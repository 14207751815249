/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, image, slug }) {
  const data = useStaticQuery<GatsbyTypes.SeoQuery>(graphql`
  query Seo {
    siteSettings: sanitySiteSettings {
      title
      baseUrl
      openGraph {
        title
        description
        image {
          asset {
            fixed(width: 1200, height: 630) {
              src
            }
          }
        }
      }
    }
  }
  `
  )

  const siteSettings = data.siteSettings
  const metaDescription = description || (siteSettings.openGraph && siteSettings.openGraph.description)
  const siteTitle = (siteSettings.openGraph && siteSettings.openGraph.title) || siteSettings.title
  const openGraphImage = image || (siteSettings.openGraph && (siteSettings.openGraph.image && siteSettings.openGraph.image.asset && siteSettings.openGraph.image.asset.fixed.src))
  const baseUrl = siteSettings.baseUrl || 'https://www.esteraluitvaartzorg.nl'
  const url = slug ? `${baseUrl}/${slug === "home" ? "" : slug + '/' }` : '';

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || siteTitle}
      titleTemplate={title && `%s | ${siteTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: 'og:image',
          content: openGraphImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `@esteraluitvaartzorg`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `nl`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.object || PropTypes.string,
  slug: PropTypes.string,
}

export default SEO
